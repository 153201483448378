import './App.css';
import Routing from "./Routing";
import Maintenance from './Pages/Maintenance';

var dev = true; //maintenance?

function isDevelopment() {
  if (dev === true) {
    return (
      <Maintenance />
    );
  }

  return (
    <Routing />
  );
}

export default function App() {
  return(
    isDevelopment()
  );
}

